.table td {
  padding: var(--spacing-stratch-xs) var(--spacing-inset-md) !important;
}

.textDisabled {
  color: #e2e2e2;
}

.loadingContainer {
  height: 80vh;
}

.membersMaxCountInfo {
  margin-top: -10px;
  color: var(--color-neutral-dark-1);
}

.loadingBtnCreateMember {
  width: 218px;
  margin: auto;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}
