.titlePage {
  color: var(--color-neutral-dark-3);
}

.subtitlePage {
  color: var(--color-neutral-dark-3);
}

.buttons {
  justify-content: center;
  margin-left: 16px;
  width: 33%;
}

.politicsText {
  color: var(--color-status-ok-base);
  cursor: pointer;
  background-color: var(--card-bg);
  border: none;
}

.labelWithError {
  color: var(--color-status-critical-base);
}

.labelWarning {
  font-size: var(--font-size-sm);
  color: var(--color-neutral-dark-5);
}
