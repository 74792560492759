.breadcrumbs {
  display: flex;
  align-items: center;
  list-style: none;
  font-family: var(--font-family-secondary);
  font-size: var(--font-size-xs);
}

.crumb a {
  display: flex;
  align-items: center;
  font-weight: var(--font-weight-regular);
  color: var(--breadcrumb-option-text-color);
  text-decoration: none;
  padding: var(--spacing-inline-5xs);
}

.crumb:first-of-type a {
  padding-left: 0;
}

/* option_current */
.crumb:last-of-type {
  font-weight: var(--font-weight-semibold);
  color: var(--breadcrumb-option-current-color);
  padding: var(--spacing-inline-5xs);
}

.crumb a:hover {
  text-decoration: underline;
}

.crumb a:focus {
  outline: none;
  background-color: var(--breadcrumb-container-bg);
}
