.avatar {
  background: var(--color-brand-primary-light);
  color: var(--color-brand-secondary-dark);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-lg);
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border-radius: var(--border-radius-circle);
  width: 50px;
  height: 50px;
  border: none;
  transition: background 0.5s;
}

.avatar:not(.clickable):focus {
  outline: none;
}

.clickable {
  cursor: pointer;
}

.clickable:hover,
.clickable:focus {
  background: var(--color-brand-primary-dark);
  color: var(--color-neutral-light-5);
}
