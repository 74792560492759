.table td {
  padding: var(--spacing-stratch-xs) var(--spacing-inset-md) !important;
}

.defaultMethod {
  color: var(--color-brand-primary-base);
  cursor: pointer;
  background-color: transparent;
  border: none;
}
