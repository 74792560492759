.indicatorContent {
  background: var(--background-indicator);
  border-radius: 100%;
  color: var(--color-indicator);
  position: absolute;
  top: -15%;
  right: -20%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  font-size: var(--font-size-2xs);
  font-weight: var(--font-weight-semibold);
}

.border {
  border: 1px solid var(--color-neutral-light-5);
}

.indicatorContainer {
  background: none;
  position: relative;
  display: flex;
}
