.color-text-subtitle {
  color: var(--color-neutral-dark-3);
}

.addedSuccessfullyMemberTexts > span {
  color: var(--color-neutral-dark-1);
}

.checkIcon {
  color: var(--color-brand-secondary-light);
}
