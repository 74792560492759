.subtitle {
  color: var(--color-neutral-dark-1);
  font-size: var(--font-size-lg);
}

.addButton {
  padding-left: var(--spacing-inline-xs);
}

.menuActions {
  cursor: pointer;
}

.titles {
  color: var(--color-neutral-dark-3);
}

.passwordInUse {
  margin-top: var(--spacing-inset-xl);
}

.passwordInUseItemTitle {
  margin-right: var(--spacing-stack-5xs);
  color: var(--color-brand-secondary-dark);
}

.textDisabled {
  color: var(--color-neutral-light-1);
}

.statusContent {
  width: 100px;
}

.labelInfoHistoryPassword {
  width: fit-content;
}

.ball {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--color-brand-primary-base);
}

.activeLabel {
  color: var(--color-brand-primary-base);
}
