.circle {
  transition: 0.35s stroke-dashoffset;
  transform: rotate(-30deg);
  transform-origin: 50% 50%;
  opacity: 0.5;
}

.circleInside {
  transition: 0.35s stroke-dashoffset;
  transform: rotate(-30deg);
  transform-origin: 50% 50%;
  stroke: var(--loading-inside-bg);
}

.indeterminateInside {
  animation-name: breath-animation;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}

.indeterminate {
  animation-name: breath-animation;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-delay: 1.3s;
}

@keyframes breath-animation {
  0% {
    transform: rotate(-30deg);
  }
  100% {
    transform: rotate(330deg);
  }
}

.progressCircularShell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  backdrop-filter: blur(var(--blur-medium));
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 99999;
}

.progressCircularFullscreen {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: var(--color-neutral-dark-2);
  position: fixed;
  opacity: var(--opacity-medium);
  top: 0;
  left: 0;
  z-index: 99991;
}

.containerFlex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
