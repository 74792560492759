.table {
  border-collapse: collapse;
  width: 100%;
  background: var(--color-neutral-light-5);
}

.table th {
  color: #fff;
  background: var(--color-brand-secondary-light);
  padding: var(--spacing-stack-3xs) var(--spacing-inset-md);
  font-weight: var(--font-weight-semibold);
}

.table td {
  color: var(--color-neutral-dark-2);
  padding: var(--spacing-stratch-sm) var(--spacing-inset-md);
  font-size: var(--font-size-sm);
}

.headerTitle {
  font-weight: var(--font-weight-bold);
}

.table tr {
  transition: background 0.3s;
  border-bottom: 1px solid var(--color-neutral-dark-5);
}

.table thead tr:first-child {
  border: none;
}

.table tbody tr:hover {
  background: var(--color-neutral-light-3);
}

.selectedRow {
  background: var(--color-neutral-light-3);
}

.table th:first-child {
  border-radius: var(--border-radius-md) 0 0 var(--border-radius-md);
}

.table th:last-child {
  border-radius: 0 var(--border-radius-md) var(--border-radius-md) 0;
}

.alignLEFT {
  display: flex;
  justify-content: flex-start;
}

.alignRIGHT {
  display: flex;
  justify-content: flex-end;
}

.alignCENTER {
  display: flex;
  justify-content: center;
}

td.inactive {
  color: var(--color-product-lightest);
}

td.inactive svg {
  filter: brightness(1.2);
}

.statusContent {
  width: 0px;
}

.statusClickable {
  cursor: pointer;
}
