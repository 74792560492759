.container {
  border: 1px solid transparent;
  border-radius: var(--border-radius-pill);
  padding: var(--spacing-stack-5xs) var(--spacing-stack-4xs);
}

.title {
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-bold);
}

.small {
  padding: var(--spacing-stack-6xs) var(--spacing-stack-4xs);
}
