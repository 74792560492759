.inputhelper {
  display: flex;
  align-items: center;
  margin-top: var(--spacing-stack-6xs);
}

.inputhelper span:first-child {
  margin-bottom: -3px;
  margin-right: 3px;
}

.inputhelper span:last-child {
  font-size: var(--font-size-xs);
}