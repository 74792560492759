.imageShell {
  margin-bottom: 76px;
}

.image {
  width: 250px;
}

.buttonsShell {
  margin-top: 105px;
}
