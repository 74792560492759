.modalContainer {
  max-height: 80vh;
  overflow: auto;
}

.accessListData {
  font-size: var(--font-size-xs);
}

.dividerCard {
  height: 1px;
  border: none;
  background-color: var(--color-neutral-light-3);
}

.errorText {
  color: var(--color-status-critical-base);
  font-size: var(--font-size-sm);
  line-height: 21px;
}

.errorIcon {
  color: var(--color-status-critical-base);
}
