/**
 * Do not edit directly
 * Generated on Tue, 01 Dec 2020 17:12:31 GMT
 */

:root {
  --color-brand-primary-darkest: #003610;
  --color-brand-primary-dark: #00863f;
  --color-brand-primary-base: #00a335;
  --color-brand-primary-light: #87c984;
  --color-brand-primary-lightest: #dcebdb;
  --color-brand-secondary-darkest: #181f21;
  --color-brand-secondary-dark: #2b373b;
  --color-brand-secondary-base: #3e5055;
  --color-brand-secondary-light: #50676e;
  --color-brand-secondary-lightest: #627f87;
  --color-complementary-information: #3C5B97;
  --color-product-darkest: #181e22;
  --color-product-dark: #262c2e;
  --color-product-base: #3f494d;
  --color-product-light: #687980;
  --color-product-lightest: #95adb7;
  --color-status-critical-darkest: #65000a;
  --color-status-critical-dark: #88000d;
  --color-status-critical-base: #cc1414;
  --color-status-critical-light: #e65c5c;
  --color-status-critical-lightest: #ffe6e6;
  --color-status-alert-darkest: #8c6200;
  --color-status-alert-dark: #cc8f00;
  --color-status-alert-base: #f8c43e;
  --color-status-alert-light: #ffd880;
  --color-status-alert-lightest: #fffae6;
  --color-status-ok-darkest: #007225;
  --color-status-ok-dark: #0e800d;
  --color-status-ok-base: #00af39;
  --color-status-ok-light: #8be68a;
  --color-status-ok-lightest: #e7ffe6;
  --color-neutral-dark-1: #1a1a1a;
  --color-neutral-dark-2: #2e2e2e;
  --color-neutral-dark-3: #404040;
  --color-neutral-dark-4: #5d5d5d;
  --color-neutral-dark-5: #888888;
  --color-neutral-light-1: #afafaf;
  --color-neutral-light-2: #c6c6c6;
  --color-neutral-light-3: #e2e2e2;
  --color-neutral-light-4: #fafafa;
  --color-neutral-light-5: #ffffff;
  --font-family-primary: 'Roboto';
  --font-family-secondary: 'Open sans';
  --font-family-primary-fallback: 'Tahoma';
  --font-family-secondary-fallback: 'Tahoma';
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --font-weight-extrabold: 900;
  --font-line-height-tight: 1.15;
  --font-line-height-medium: 1.45;
  --font-line-height-distant: 1.85;
  --font-size-2xs: 10px;
  --font-size-xs: 12px;
  --font-size-sm: 14px;
  --font-size-base: 16px;
  --font-size-lg: 18px;
  --font-size-xl: 24px;
  --font-size-2xl: 28px;
  --font-size-3xl: 32px;
  --font-size-4xl: 36px;
  --font-size-5xl: 40px;
  --border-radius-none: 0px;
  --border-radius-xs: 1px;
  --border-radius-sm: 4px;
  --border-radius-md: 10px;
  --border-radius-lg: 18px;
  --border-radius-pill: 50px;
  --border-radius-circle: 50%;
  --border-width-none: 0px;
  --border-width-small: 1px;
  --border-width-medium: 2px;
  --border-width-large: 4px;
  --border-width-big: 8px;
  --opacity-opaque: 1;
  --opacity-intense: 0.8;
  --opacity-medium: 0.5;
  --opacity-light: 0.3;
  --opacity-semi-transparent: 0.1;
  --blur-intense: 12px;
  --blur-medium: 4px;
  --blur-light: 1px;
  --shadow-none: 0px 0px 0px;
  --shadow-level-1: 0px 1px 3px;
  --shadow-level-2: 0px 3px 6px;
  --shadow-level-3: 0px 6px 9px;
  --shadow-level-4: 0px 10px 20px;
  --spacing-stack-none: 0px;
  --spacing-stack-6xs: 4px;
  --spacing-stack-5xs: 6px;
  --spacing-stack-4xs: 12px;
  --spacing-stack-3xs: 14px;
  --spacing-stack-2xs: 16px;
  --spacing-stack-xs: 20px;
  --spacing-stack-sm: 30px;
  --spacing-stack-md: 38px;
  --spacing-stack-lg: 46px;
  --spacing-stack-xl: 52px;
  --spacing-stack-2xl: 64px;
  --spacing-stack-3xl: 76px;
  --spacing-stack-4xl: 92px;
  --spacing-stack-5xl: 145px;
  --spacing-inset-5xs: 1px;
  --spacing-inset-4xs: 3px;
  --spacing-inset-3xs: 5px;
  --spacing-inset-2xs: 8px;
  --spacing-inset-xs: 10px;
  --spacing-inset-sm: 12px;
  --spacing-inset-md: 14px;
  --spacing-inset-lg: 16px;
  --spacing-inset-xl: 18px;
  --spacing-inset-2xl: 20px;
  --spacing-inset-3xl: 22px;
  --spacing-inset-4xl: 24px;
  --spacing-inline-5xs: 4px;
  --spacing-inline-4xs: 8px;
  --spacing-inline-3xs: 16px;
  --spacing-inline-2xs: 22px;
  --spacing-inline-xs: 32px;
  --spacing-inline-sm: 44px;
  --spacing-inline-md: 52px;
  --spacing-inline-lg: 60px;
  --spacing-squish-quarck: 8px;
  --spacing-squish-nano: 12px;
  --spacing-squish-xxxs: 14px;
  --spacing-squish-xxs: 20px;
  --spacing-squish-xs: 28px;
  --spacing-squish-sm: 32px;
  --spacing-squish-md: 36px;
  --spacing-squish-lg: 40px;
  --spacing-squish-xl: 44px;
  --spacing-stratch-quarck: 4px;
  --spacing-stratch-nano: 8px;
  --spacing-stratch-xxxs: 12px;
  --spacing-stratch-xxs: 18px;
  --spacing-stratch-xs: 24px;
  --spacing-stratch-sm: 32px;
  --spacing-stratch-md: 36px;
  --spacing-stratch-lg: 40px;

  // TODO: Not defined
  --color-status-invalid: #d65151;
  --color-neutral-light-6: #dbdbdb;
  --color-neutral-light-7: #6c6c6c;
  --color-neutral-light-8: #3c3c3c;
  --color-neutral-light-9: #707070;
  --color-neutral-light-10: #1f272b;
  --color-neutral-light-11: #505050;
  --color-neutral-light-12: #efefef;
  --color-neutral-dark-6: #272727;
  --color-neutral-dark-7: #283237;
  --color-neutral-dark-8: #696969;
  --color-neutral-dark-9: #22282a;
  --color-neutral-dark-10: #272f33;
  --color-neutral-dark-11: #4e5558;
  --color-gray-opacity-3: rgba(29, 29, 29, 0.3);
  --color-link: #0093ff;
}
