.columnsGap {
  column-gap: var(--spacing-stack-2xs);
}

.dividerCard {
  height: 1px;
  border: none;
  background-color: var(--color-neutral-light-3);
}

.labelDetails {
  display: block;
  font-size: var(--font-size-xs);
  color: var(--color-neutral-dark-4);
}

.iconContainer {
  color: var(--color-brand-primary-darkest);
}

.icon {
  cursor: pointer;
}

.modalText {
  font-size: var(--font-size-base);
  line-height: var(--font-line-height-medium);
  font-weight: var(--font-weight-regular);
}

.itemList {
  display: block;
  margin-left: var(--spacing-inset-lg);
}

.modalBold {
  font-weight: var(--font-weight-bold);
}

.loading {
  height: calc(100vh - 192px);
}
