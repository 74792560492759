.inputImage {
  border: 1px solid var(--color-brand-secondary-base);
  display: inline-block;
  width: fit-content;
  border-radius: var(--border-radius-sm);
  transition: 0.3s all;
}

.inputImageWithoutImage:hover {
  background-color: var(--color-brand-secondary-base);
  color: var(--color-neutral-light-5);
  border-color: transparent;
}

.inputImageWithImage {
  background-color: var(--color-brand-secondary-base);
  color: var(--color-neutral-light-5);
}

.inputImageWithImage:hover {
  color: var(--color-neutral-dark-2) !important;
  background-color: transparent;
}

.imageTitle {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.inputImageInvalid {
  border: 1px solid var(--color-status-critical-base);
  color: var(--color-neutral-light-5) !important;
}

.inputFile {
  width: 100%;
  height: 100%;
  padding: var(--spacing-stack-4xs) var(--spacing-inset-3xl);
  cursor: pointer;
}
