.loadingShell {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.shellConnectionLog {
  padding: var(--spacing-stack-2xl) !important;
  padding-bottom: var(--spacing-stack-xl) !important;
}

.bodyOfConnectionLogs {
  padding: var(--spacing-stack-sm);
}

.colorTitleAndSubtitle {
  color: var(--color-neutral-dark-3);
}

.downloadButton {
  width: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.customLink {
  text-decoration: underline;
  color: var(--color-brand-primary-base);
}

.subtitleInput {
  color: var(--color-product-light);
}
