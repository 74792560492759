.alert {
  font-family: var(--font-family-secondary);
  font-size: var(--font-size-xs);
  padding: var(--spacing-inset-2xs) var(--spacing-inset-sm);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.icon {
  font-size: var(--font-size-lg);
  margin-right: var(--spacing-inset-sm);
}

.text {
  background: transparent;
  border: none;
}

.success {
  color: var(--color-status-ok-darkest);
}

.success:not(.text) {
  background: var(--color-status-ok-lightest);
  border-radius: var(--border-radius-sm);
  border: var(--border-width-small) solid var(--color-status-ok-darkest);
}

.warning {
  color: var(--color-status-alert-dark);
}

.warning:not(.text) {
  background: var(--color-status-alert-lightest);
  border-radius: var(--border-radius-sm);
  border: var(--border-width-small) solid var(--color-status-alert-dark);
}

.error {
  color: var(--color-status-critical-dark);
}

.error:not(.text) {
  background: var(--color-status-critical-lightest);
  border-radius: var(--border-radius-sm);
  border: var(--border-width-small) solid var(--color-status-critical-dark);
}

.info {
  color: var(--color-product-light);
}

.info:not(.text) {
  background: var(--color-neutral-light-4);
  border-radius: var(--border-radius-sm);
  border: var(--border-width-small) solid var(--color-product-light);
}
