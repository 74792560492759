.container {
  position: relative;
  width: 100%;
  min-height: 45vh;
  margin-top: var(--spacing-stack-lg);
}

.rangeContainer {
  width: 50%;
}
