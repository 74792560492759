.modalText {
  font-size: var(--font-size-base);
  line-height: var(--font-line-height-medium);
  font-weight: var(--font-weight-regular);
}
.iconContainer {
  color: var(--color-brand-primary-darkest);
}

.icon {
  cursor: pointer;
}
