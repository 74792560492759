.responseMessageContainer {
  display: flex;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  color: var(--response-message-color);
  font-family: var(--font-family-secondary);
  text-align: left;
  justify-content: flex-start;
  align-items: center;
}
