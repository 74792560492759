.inputOfDatePicker:read-only {
  width: 100%;
  background: var(--input-bg) !important;
  cursor: pointer;
  border-color: var(--input-border-color);
}

.inputOfDatePicker > span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.placeholder {
  color: var(--color-neutral-dark-5) !important;
}
