.title {
  color: var(--color-neutral-dark-3);
}

.cardPlace {
  color: var(--color-neutral-dark-3);
  padding: 0;
  min-height: 195px;
}

.menuContainer {
  max-height: 24px;
}

.menuActions {
  margin-top: -6px;
  cursor: pointer;
}

.textDisabled {
  color: #e2e2e2;
}

.createPlaceButton {
  width: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

.iconContainer {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-brand-primary-base);
  background-color: var(--color-status-ok-lightest);
  border-radius: var(--border-radius-sm);
}

.hiddenAlert {
  visibility: hidden;
}

.loadingShell {
  display: flex;
  justify-content: center;
  align-content: center;
  height: calc(100vh - 300px);
  width: 100%;
}

.dueToneIcons {
  margin-right: var(--spacing-stack-2xl);
}

.shellPlaces {
  padding: var(--spacing-stack-2xl) !important;
  padding-bottom: var(--spacing-stack-xl) !important;
}

.bodyPlaces {
  width: 100%;
  background-color: var(--color-neutral-light-5);
}
