.tag {
  display: inline-flex;
  font-family: var(--font-family-secondary);
  font-size: var(--font-size-2xs);
  font-weight: var(--font-weight-bold);
  line-height: var(--font-line-height-tight);
  border-radius: var(--border-radius-lg);
  border: var(--border-width-small) solid;
  padding: var(--spacing-inset-2xs) var(--spacing-inset-sm);
}

.wide {
  padding: var(--spacing-inset-2xs) var(--spacing-inset-3xl);
}

.fullWidth {
  width: 100%;
  justify-content: center;
}
