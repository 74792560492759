.loading {
  height: calc(100vh - 192px);
}

.statusCelule div + div {
  margin-left: var(--spacing-stack-5xs);
}

.tooltipTagContainer {
  display: inline-flex;
}
