.container {
  background: var(--color-neutral-light-4);
}

.stepsContainer {
  width: 100%;
}

.cardFirstAccess {
  margin: var(--spacing-stack-xl) 0;
  padding: 65px;
  width: 750px;
}
