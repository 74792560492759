.titlePage {
  color: var(--color-neutral-dark-3);
}

.subtitlePage {
  color: var(--color-neutral-dark-3);
}

.customLink {
  text-decoration: underline;
  color: var(--color-brand-primary-base);
  cursor: pointer;
}
