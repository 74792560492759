.shellMenu {
  position: relative;
  display: flex;
  cursor: default;
}

.shellMenuMiddle {
  display: flex;
  justify-content: center;
}

.menu {
  background: white;
  box-shadow: var(--shadow-level-2) rgba(0, 0, 0, 0.15);
  border-radius: var(--border-radius-md);
  position: absolute;
  min-width: var(--width-menu);
  transform: scale(0);
  transform-origin: right top;
  transition: transform 0.5s;
  z-index: 99;
}

.menuItem {
  font-size: var(--font-size-base);
  padding: var(--spacing-stack-4xs);
}

.menuItem:first-child {
  border-radius: var(--border-radius-md) var(--border-radius-md) 0 0;
}

.menuItem:last-child {
  border-radius: 0 0 var(--border-radius-md) var(--border-radius-md);
}

.menuItem:only-child {
  border-radius: var(--border-radius-md) var(--border-radius-md);
}

.menuItem:hover {
  background: var(--color-neutral-light-3);
  cursor: pointer;
}

.disabled {
  pointer-events: none;
  background: var(--color-neutral-light-3);
  color: var(--color-neutral-dark-5);
  cursor: auto !important;
}

.menuEnterActive {
  transform: scale(0);
  transform-origin: right top;
}
.menuEnterDone {
  transform: scale(1);
  transform-origin: right top;
}
.menuEnterExitActive {
  transform: scale(1);
  transform-origin: right top;
}
.menuDrawerExitDone {
  transform: scale(0);
  transform-origin: right top;
}

/* Menu positions */
.center {
  bottom: auto;
  top: auto;
}

.middle {
  left: auto;
  right: auto;
}

.bottom {
  top: 130%;
}

.top {
  bottom: 130%;
}

.right {
  left: 110%;
}

.left {
  right: 110%;
}

.start {
  left: 0%;
}

.end {
  right: 0%;
}
