/* Estrutura dos tokens: [tipo]-[breakpoint]-[tamanho]
* tipo: title/text
* breakpoint: xl/sm
* tamanho: xl/lg/base/sm
*/

/* breakpoint xl */
.title-xl-xl {
  font-family: var(--font-family-primary);
  font-style: normal;
  font-weight: var(--font-weight-extrabold);
  font-size: var(--font-size-5xl);
}

.title-xl-lg {
  font-family: var(--font-family-primary);
  font-style: normal;
  font-weight: var(--font-weight-extrabold);
  font-size: var(--font-size-3xl);
}

.title-xl-base {
  font-family: var(--font-family-primary);
  font-style: normal;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-xl);
}

.title-xl-sm {
  font-family: var(--font-family-primary);
  font-style: normal;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-base);
}

/* breakpoint sm */
.title-sm-xl {
  font-family: var(--font-family-primary);
  font-style: normal;
  font-weight: var(--font-weight-extrabold);
  font-size: var(--font-size-3xl);
}

.title-sm-lg {
  font-family: var(--font-family-primary);
  font-style: normal;
  font-weight: var(--font-weight-extrabold);
  font-size: var(--font-size-xl);
}

.title-sm-base {
  font-family: var(--font-family-primary);
  font-style: normal;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-lg);
}

.title-sm-sm {
  font-family: var(--font-family-primary);
  font-style: normal;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-sm);
}
