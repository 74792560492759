.circleStep {
  float: left;
  padding: 0 30px;
  position: relative;
  text-align: center;
  color: var(--circle-step-color);
  font-weight: 400;
  width: 100%;
  font-size: var(--font-size-xs);
  word-break: break-all;
}

.activeCircleStep {
  color: var(--active-circle-step-color);
  font-weight: 700;
}

.circleStep.activeCircleStep::before {
  background: var(--before-active-circle-step-bg);
  color: var(--before-active-circle-step-color);
}

.circleStep::before {
  content: counter(step);
  counter-increment: step;
  width: 45px;
  height: 45px;
  background: var(--before-circle-step-bg);
  color: var(--color-neutral-light-1);
  border: 1px solid var(--before-circle-step-border-color);
  box-shadow: var(--shadow-level-1) rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 10px auto;
  border-radius: 100%;
  text-align: center;
  z-index: 2;
}

.circleStep.activeCircleStep::after {
  background: var(--after-active-circle-step-bg);
}

.circleStep::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 4px;
  background: var(--after-circle-step-bg);
  top: 22.5px;
  left: -50%;
  z-index: -1;
}

.circleStep:first-child:after {
  content: none;
}

.containerStep {
  width: 100%;
  position: relative;
  min-height: 66px;
}

.stepsShell {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: baseline;
  counter-reset: step;
  list-style-type: none;
  z-index: 1;
  position: absolute;
}
