.shellAction {
  position: relative;
}

.sidebarAction {
  width: 100%;
  height: 55px;
  background: transparent;
  border: none;
  border-bottom: 1px solid var(--color-neutral-dark-1);
  padding: var(--spacing-inset-xl) var(--spacing-inset-4xl);
  color: var(--color-neutral-light-5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
}

.sidebarActionActive {
  background: transparent linear-gradient(91deg, #181e22 0%, #00000003 100%) 0%
    0% no-repeat padding-box;
}

.leftHighlight {
  width: 4px;
  height: 100%;
  position: absolute;
  left: 0;
  transition: background 0.4s;
}

.sidebarActionActive .leftHighlight {
  background: var(--color-brand-primary-base);
}

.sidebarActionMinimized {
  justify-content: center;
  border-bottom: 0.5px solid #555d61;
}

.shellLabel {
  color: var(--color-neutral-light-5);
  display: flex;
  gap: var(--spacing-inset-lg);
  display: flex;
  justify-content: center;
  align-items: center;
}

.label {
  font-size: var(--font-size-base);
  font-weight: 700;
}

.icon {
  font-size: 22px;
  display: flex;
}

.item a {
  height: 44px;
  width: 100%;
  background: transparent;
  color: var(--color-neutral-light-5);
  border-bottom: 1px solid var(--color-neutral-dark-1);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: var(--font-size-xs);
  cursor: pointer;
  padding: 0px 62px;
  text-decoration: none;
}

.item {
  list-style: none;
  text-transform: uppercase;
}

.itemActive a:only-child {
  color: var(--color-brand-primary-base);
  font-weight: 700;
}

.item a:hover {
  color: var(--color-brand-primary-base);
  font-weight: 700;
}

.itemsMinimized .item a {
  border: none;
}

.itemsMinimized .itemActive a,
.itemsMinimized .item a:hover {
  background: transparent linear-gradient(298deg, #00a3352b 0%, #00a533c7 100%)
    0% 0% no-repeat padding-box;
  color: var(--color-neutral-light-5);
  font-weight: 700;
}

.items {
  overflow: hidden;
  transform-origin: left;
  opacity: 0;
  transition: 0.4s;
  margin-top: var(--content-height);
  pointer-events: none;
  margin-left: -100%;
}

.itemsDuringMinimizing {
  display: none;
}

.itemsMinimized {
  position: absolute;
  left: 80px;
  top: 3px;
  background: transparent
    linear-gradient(161deg, #181e22 0%, #2c383d 33%, #181e22 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 2px;
  width: max-content;
  transform: scaleX(0);
  transition: 0.4s;
  transform-origin: left top;
}

.itemsMinimizedEnterActive {
  transform: scale(0, 0);
  pointer-events: all;
  opacity: 0;
}

.itemsMinimizedEnterDone {
  transform: scale(1, 1);
  pointer-events: all;
  opacity: 1;
}

.itemsMinimizedEnterExitActive {
  transform: scale(1, 1);
  pointer-events: none;
  opacity: 1;
}

.itemsMinimizedDrawerExitDone {
  transform: scale(0, 0);
  pointer-events: none;
  opacity: 0;
}

.itemsEnterActive {
  transform: scaleX(0);
  pointer-events: all;
  margin-left: -100%;
  margin-top: var(--content-height);
  opacity: 0;
}

.itemsEnterDone {
  transform: scaleX(1);
  pointer-events: all;
  margin-left: 0;
  margin-top: 0px;
  opacity: 1;
}

.itemsEnterExitActive {
  transform: scaleX(1);
  pointer-events: none;
  margin-left: 0;
  margin-top: 0px;
  opacity: 1;
}

.itemsDrawerExitDone {
  transform: scaleX(0);
  pointer-events: none;
  margin-left: -100%;
  margin-top: var(--content-height);
  opacity: 0;
}
