.addDeviceContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 115px);
  text-align: center;
  flex-direction: column;
  position: relative;
}

.addDeviceContainer > h1 {
  width: 60%;
}

.bottomActionsButton {
  display: flex;
  justify-content: flex-end;
  bottom: 0;
  width: 100%;
  position: sticky;
  background: var(--color-neutral-light-5);
  padding: var(--spacing-stratch-lg);
  border-top: solid 1px var(--color-neutral-light-3);
}

.loading {
  height: calc(100vh - 192px);
}

.labelActivePortal {
  color: var(--color-status-ok-base);
}

.ball {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--color-brand-primary-base);
}
