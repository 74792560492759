.textDisable {
  color: var(--color-neutral-light-1);
}

.table td {
  padding: var(--spacing-stratch-xs) var(--spacing-inset-md) !important;
}

.textDisable {
  color: var(--color-neutral-light-1);
}

.table td {
  padding: var(--spacing-stratch-xs) var(--spacing-inset-md) !important;
}
