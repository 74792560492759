.navbar {
  height: 83px;
  background: var(--color-neutral-light-5);
  padding: 0px var(--spacing-stack-lg) 0px 82px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  right: 0;
}

.backToSitesButton {
  border: none;
  width: 30px;
  height: 33px;
  color: var(--color-brand-primary-dark);
  background: var(--color-neutral-light-5);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.siteTitle {
  color: var(--color-neutral-dark-3);
  text-transform: uppercase;
}

.navbarFixed {
  position: fixed;
  z-index: 5;
}

.navbarWithoutLogo {
  justify-content: flex-end;
}

.logo {
  width: auto;
}

.logoShell {
  width: 175px;
}

.hasMenu {
  width: calc(100% - 345px);
}

@keyframes notification-bell {
  0% {
    transform: scale(1);
    border-radius: 100px;
  }
  25% {
    transform: scale(1.3);
  }
  30% {
    transform: rotate(15deg) scale(1.3);
  }
  40% {
    transform: rotate(-15deg) scale(1.3);
  }
  50% {
    transform: rotate(15deg) scale(1.3);
  }
  60% {
    transform: rotate(-15deg) scale(1.3);
  }
  100% {
    transform: rotate(0);
  }
}

@keyframes notification-bell-indicator {
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.notificationShell {
  animation-name: notification-bell;
  animation-duration: 2s;
}

.indicatorNotifications {
  transform-origin: left;
  animation-name: notification-bell-indicator;
  animation-duration: 2s;
}

@media only screen and (max-width: 1400px) {
  .navbar {
    padding: 0 var(--spacing-inline-sm);
  }
}

@keyframes notification-with-message-animation {
  0% {
    width: 56px;
  }
  15% {
    width: 100%;
    background: var(--color-brand-secondary-base);
  }
  75% {
    width: 100%;
    background: var(--color-brand-secondary-base);
  }
  90% {
    width: 56px;
  }
  100% {
    background: transparent;
  }
}

@keyframes notification-with-message-animation-text {
  0% {
    position: relative;
    transform: scaleX(0);
  }
  15% {
    transform: scaleX(1);
  }
  75% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}

@keyframes bell-of-notification-with-text-animation {
  10% {
    color: var(--color-neutral-light-5);
  }
  75% {
    color: var(--color-neutral-light-5);
  }
  100% {
    color: var(--color-neutral-dark-1);
  }
}

.notificationWithMessage {
  animation-duration: 4s;
  transform-origin: right;
  animation-name: notification-with-message-animation;
  background: transparent;
  border-radius: var(--border-radius-sm);
  color: var(--color-neutral-light-5);
  padding-left: var(--spacing-inline-3xs);
  cursor: pointer;
}

.notificationWithMessage > div {
  animation-duration: 4s;
  transform-origin: right;
  animation-name: notification-with-message-animation-text;
  min-width: max-content;
  position: relative;
}

.notificationWithMessage > button > span {
  animation-duration: 4s;
  transform-origin: right;
  animation-name: bell-of-notification-with-text-animation;
  transition: 0.1s;
}

.notificationWithMessage > button {
  height: 45px;
  box-shadow: none !important;
}

.withoutNotificationText {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 56px;
  height: 45px;
}

.withoutNotificationText > div {
  transform: scale(0);
}

.centralMenuItem {
  text-decoration: none;
  color: var(--color-product-lightest);
  transition: 0.5s;
}

.centralMenuItem > svg {
  margin-right: var(--spacing-stack-6xs);
}

.centralMenuItem > span {
  font-size: var(--font-size-xl);
}

.centralMenuActiveItem {
  color: var(--color-brand-primary-dark);
}

.centralMenuActiveItem > span {
  font-weight: var(--font-weight-extrabold);
}

.logoutBtn {
  color: var(--color-status-critical-base);
}

.dividerMenu {
  width: 95%;
}

.emailStyles {
  font-size: 12px;
  color: var(--color-brand-primary-base);
}
