.shellAccessLists {
  padding: var(--spacing-stack-2xl) !important;
  padding-bottom: var(--spacing-stack-xl) !important;
}

.title {
  color: var(--color-neutral-dark-3);
}

.textDisabled {
  color: #e2e2e2;
}

.colListTypeText {
  font-size: var(--font-size-sm);
}
