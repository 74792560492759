.shellAccessLists {
  padding: var(--spacing-stack-2xl) !important;
  padding-bottom: var(--spacing-stack-xl) !important;
}

.title {
  color: var(--color-neutral-dark-3);
}

.textDisabled {
  color: #e2e2e2;
}

.colListTypeText {
  font-size: var(--font-size-sm);
}

.backToAccessListsButton {
  border: none;
  width: 24px;
  height: 24px;
  color: var(--color-neutral-light-5);
  background: var(--color-brand-primary-dark);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.tabHeader > ul {
  max-width: 65vw;
  margin: var(--spacing-inline-xs) 0;
}

.shellSteps {
  margin: auto;
  max-width: 36vw;
}

.shellSteps > div > div > div {
  word-break: normal;
}

.shellCard {
  margin-top: var(--spacing-stack-2xl);
}
