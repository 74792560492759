.templateMenuContent {
  margin-left: 345px;
  padding: var(--spacing-stack-xl) var(--spacing-stack-lg)
    var(--spacing-stack-3xl) var(--spacing-stack-3xl);
  padding-top: 115px;
  transition: margin-left 0.4s;
  min-height: 100vh;
}

.templateMenuContentFull {
  margin-left: 75px;
}

@media only screen and (max-width: 1400px) {
  .templateMenuContent {
    padding: var(--spacing-stack-xl) var(--spacing-stack-md);
    padding-top: 115px;
  }
}
