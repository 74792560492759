.switch {
  position: relative;
  display: inline-block;
  width: 39px;
  height: 20px;
  cursor: pointer;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch + span {
  margin-left: 10px;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--toggle-off-bg-color);
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 5px;
  bottom: 4px;
  background-color: var(--color-neutral-light-5);
  border-radius: 34px;
  box-shadow: 0px 1px 3px #00000059;
}

input:checked + .slider:before {
  transform: translateX(17px);
  background-color: var(--toggle-on-dot-color);
}

input:checked + .slider {
  background-color: var(--toggle-on-bg-color);
}

.disabled {
  pointer-events: none;
}

.disabled .slider {
  background-color: var(--toggle-disabled-bg-color);
}

.disabled + span {
  color: var(--toggle-disabled-bg-color);
}

.disabled .slider:before {
  background-color: var(--toggle-disabled-dot-color);
}

.disabled input:checked + .slider {
  background-color: var(--toggle-disabled-bg-color);
}

.disabled input:checked + .slider:before {
  background-color: var(--toggle-disabled-dot-color);
}

input:checked + .slider.color {
  background-color: var(--toggle-color-bg-color);
}

.disabled input:checked + .slider.color {
  background-color: var(--toggle-disabled-bg-color);
}