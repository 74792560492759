.textSmallXs {
  font-size: var(--font-size-xs);
  color: var(--color-brand-secondary-dark);
}

.cardShell {
  margin-bottom: var(--spacing-stack-4xs);
}

.messageShell {
  height: calc(100vh - 655px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.messageShell > div {
  width: 100%;
}
