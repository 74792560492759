.container {
  padding: var(--spacing-inset-sm) var(--spacing-stack-xs);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-color: var(--color-neutral-light-3);
  padding: var(--spacing-stack-5xs) 0;
  transition: 0.5s;
}

.headerIsExpanded {
  padding-bottom: 18px;
}

.content {
  height: 0px;
  overflow: hidden;
  transition: height 500ms;
  transition: 0.5s;
}

.contentIsExpanded {
  height: auto !important;
  transition: none !important;
}

.innerContent {
  padding: var(--spacing-stack-2xs) 0;
  padding-top: var(--spacing-inset-4xl);
}

.contentEnterActive {
  height: var(--content-height);
  transition: height 500ms;
}

.contentEnterDone {
  height: var(--content-height);
}

.contentExitActive {
  height: 0px;
  transition: height 500ms;
}

.contentExitDone {
  height: 0px;
}
