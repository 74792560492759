.subtitle {
  position: absolute;
  top: 0;
  margin-top: var(--spacing-stack-2xl);
  font-size: var(--font-size-sm);
}

.modalContent {
  margin-top: var(--spacing-stack-sm);
  overflow: auto;
  max-height: 60vh;
}
