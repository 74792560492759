.cardContainer {
  box-sizing: border-box;
  display: block;
  overflow-wrap: break-word;
  padding: var(--spacing-inset-lg);
  width: auto;
  height: auto;
  max-height: auto;
  background: var(--color-neutral-light-5);
  border: 1px solid transparent;
  background: var(--card-bg);
  color: var(--card-color);
  transition: 0.3s;
}

.shadowMD:hover {
  box-shadow: var(--shadow-level-1) rgba(0, 0, 0, 0.15);
}

.shadowLG:hover {
  box-shadow: var(--shadow-level-2) rgba(0, 0, 0, 0.15);
}

.shadow3XL:hover {
  box-shadow: var(--shadow-level-4) rgba(0, 0, 0, 0.15);
}

.hoverable:hover {
  transition: 0.3s;
  border: 2px var(--color-brand-secondary-light) solid !important;
}

.paddingless {
  padding: 0;
}

.roundedSM {
  border-radius: var(--border-radius-sm);
}

.roundedMD {
  border-radius: var(--border-radius-md);
}

.clickable {
  cursor: pointer;
}
