.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  gap: var(--spacing-inline-xs);
}

.shellSelectPagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-inset-2xs);
}

.shellPrevNextButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-inset-2xs);
}
