.container {
  padding: var(--spacing-stack-2xl);
}

.containerCard {
  height: calc(100vh - 211px);
}

.contentCard {
  max-width: 650px;
}

h1.errorCode {
  font-size: 64px;
}
