.overlayDrawer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
  z-index: -1;
  transition: background 500ms;
}

.overlayDrawerHidden {
  background: #fff0;
  z-index: -1;
  pointer-events: none;
}

.overlayDrawerEnterActive {
  background: rgba(26, 26, 26, 0.5);
  transition: background 500ms;
  z-index: 99;
}

.overlayDrawerEnterDone {
  background: rgba(26, 26, 26, 0.5);
  z-index: 99;
}

.overlayDrawerEnterExitActive {
  background: #fff0;
  transition: background 500ms;
  z-index: 99;
}

.containerDrawer {
  width: var(--drawer-width);
  background: #fff;
  height: 100vh;
  border-radius: var(--border-radius-md) 0 0 var(--border-radius-md);
  position: fixed;
  top: 0;
  z-index: 100;
  overflow: auto;
  right: calc(var(--drawer-width) * -1);
  transition: visibility 500ms, right 500ms;
  visibility: hidden;
}

.containerDrawerEnterActive {
  visibility: visible;
  right: 0;
  transition: visibility 500ms, right 500ms;
}

.containerDrawerEnterDone {
  visibility: visible;
  right: 0;
}

.containerDrawerExitActive {
  right: calc(var(--drawer-width) * -1);
  transition: visibility 500ms, right 500ms;
}

.containerDrawerExitDone {
  right: calc(var(--drawer-width) * -1);
  z-index: -5;
  visibility: hidden;
  background: #fff0;
  z-index: -1;
  pointer-events: none;
}

.topDrawer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--color-neutral-light-3);
  padding: var(--spacing-stack-2xs) var(--spacing-inline-xs);
}

.titleDrawer {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-bold);
  font-style: normal;
  font-size: var(--font-size-xl);
  line-height: var(--font-size-3xl);
  width: 100%;
  color: var(--color-neutral-dark-1);
}

.contentDrawer {
  padding: var(--spacing-inset-4xl) var(--spacing-inline-xs);
}

.closebleArea {
  width: calc(100vw - var(--drawer-width));
  height: 100vh;
}

@media only screen and (min-width: 480px) {
  .containerDrawer {
    --drawer-width: var(--drawer-width-xs) !important;
  }
}

@media only screen and (min-width: 768px) {
  .containerDrawer {
    --drawer-width: var(--drawer-width-sm) !important;
  }
}

@media only screen and (min-width: 1024px) {
  .containerDrawer {
    --drawer-width: var(--drawer-width-md) !important;
  }
}

@media only screen and (min-width: 1280px) {
  .containerDrawer {
    --drawer-width: var(--drawer-width-lg) !important;
  }
}

@media only screen and (min-width: 1920px) {
  .containerDrawer {
    --drawer-width: var(--drawer-width-xl) !important;
  }
}
