/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
}

.container input {
  display: none;
}

.checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 16px;
  width: 16px;
  border-style: solid;
  border-width: 1px;
  border-color: var(--radio-border-color);
  background-color: var(--radio-background-color);
  border-radius: 50%;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  top: 2px;
  left: 2px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--radio-dot-color);
}

.disabled .checkmark:after {
  background-color: var(--radio-disabled-color);
}

.disabled span {
  border-color: var(--radio-disabled-color);
}

.disabled {
  pointer-events: none;
  color: var(--radio-disabled-color);
}
