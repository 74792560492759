.toastContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  position: fixed;
  height: calc(100vh - 9vh);
  z-index: 100;
  pointer-events: none;
  gap: var(--spacing-inset-3xs);
}

.top-right {
  top: 115px;
  right: 60px;
}
@keyframes toast-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.toast {
  font-family: var(--font-family-secondary);
  font-size: var(--font-size-base);
  min-width: 294px;
  border-radius: var(--border-radius-sm);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 var(--spacing-squish-xxs);
  pointer-events: all;
  z-index: 101;

  animation-name: toast-animation;
  animation-duration: 0.6s;
  opacity: 1;
  z-index: 101;
  transition: all 0.6s ease-in-out;
}

.toastHide {
  opacity: 0;
  visibility: hidden;
}

.toastDisplayNone {
  display: none;
}

.toastContent {
  padding: var(--spacing-stack-3xs) 0;
  word-break: break-word;
  max-width: 480px;
}

.toastContent,
.toastContent > * {
  color: var(--color-neutral-light-5);
}

.toastIcon {
  margin-right: var(--spacing-inset-md);
}

.closeButton {
  margin-left: var(--spacing-inset-md);
  display: flex;
  cursor: pointer;
}

.default {
  background-color: var(--color-brand-secondary-light);
}

.info {
  background-color: var(--color-brand-secondary-light);
}

.success {
  background-color: var(--color-brand-primary-dark);
}

.error {
  background-color: var(--color-status-critical-base);
}

.warning {
  background-color: var(--color-brand-secondary-light);
}
