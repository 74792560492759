.comboboxInput {
  text-align: left;
  border-radius: var(--border-radius-sm);
  border-width: var(--border-width-small);
  border-color: var(--input-border-color);
  border-style: solid;
  padding: var(--spacing-inset-xs);
  font-size: var(--font-size-base);
  background-color: var(--select-bg);
  color: var(--select-color);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-width: fit-content;
  gap: var(--spacing-inset-2xs);
  min-height: 56px;
  background: var(--color-neutral-light-5);
}

.comboboxInput:hover:not([disabled]) {
  box-shadow: 0px 3px 6px #00000029;
}

.comboboxInput:focus:not([disabled]) {
  border-color: var(--color-neutral-dark-1);
}
.comboboxInput[disabled] {
  color: var(--color-neutral-light-1);
  background-color: var(--color-neutral-light-3);
  border-color: var(--color-neutral-light-3);
  cursor: auto;
}

.iconCloseShell {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: var(--color-neutral-light-5);
  cursor: pointer;
  color: var(--color-brand-secondary-base);
}

.placeholder {
  color: var(--color-neutral-light-1);
}

.error {
  border-color: var(--color-status-critical-base) !important;
}

.warning {
  border-color: var(--color-status-alert-base) !important;
}

.success {
  border-color: var(--color-status-ok-base) !important;
}

.shellCombobox > div > div {
  position: relative;
  z-index: 1;
}

.outsideClick {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
}
