/* display, flex e alinhamentos */
.d-flex {
  display: flex !important;
}

.d-block {
  display: block;
}

.d-inline-flex {
  display: inline-flex;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.align-start {
  align-items: flex-start;
}

.align-stretch {
  align-items: stretch;
}

.flex-column {
  flex-direction: column;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.fit-height {
  height: 100%;
}

.fit-width {
  width: 100%;
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: 700;
}

.text-uppercase {
  text-transform: uppercase;
}

.position-relative {
  position: relative;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.pa-1 {
  padding: 0.25rem 0.25rem !important;
}

.ma-1 {
  margin: 0.25rem 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.pa-2 {
  padding: 0.5rem 0.5rem !important;
}

.ma-2 {
  margin: 0.5rem 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.pa-3 {
  padding: 1rem 1rem !important;
}

.ma-3 {
  margin: 1rem 1rem !important;
}

.mt-4 {
  margin-top: 1.25rem !important;
}

.pt-4 {
  padding-top: 1.25rem !important;
}

.mb-4 {
  margin-bottom: 1.25rem !important;
}

.pb-4 {
  padding-bottom: 1.25rem !important;
}

.my-4 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.py-4 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.ml-4 {
  margin-left: 1.25rem !important;
}

.pl-4 {
  padding-left: 1.25rem !important;
}

.mr-4 {
  margin-right: 1.25rem !important;
}

.pr-4 {
  padding-right: 1.25rem !important;
}

.mx-4 {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}

.px-4 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.pa-4 {
  padding: 1.25rem 1.25rem !important;
}

.ma-4 {
  margin: 1.25rem 1.25rem !important;
}

.mt-5 {
  margin-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.ml-5 {
  margin-left: 1.5rem !important;
}

.pl-5 {
  padding-left: 1.5rem !important;
}

.mr-5 {
  margin-right: 1.5rem !important;
}

.pr-5 {
  padding-right: 1.5rem !important;
}

.mx-5 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.px-5 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.pa-5 {
  padding: 1.5rem 1.5rem !important;
}

.ma-5 {
  margin: 1.5rem 1.5rem !important;
}

.mt-6 {
  margin-top: 1.75rem !important;
}

.pt-6 {
  padding-top: 1.75rem !important;
}

.mb-6 {
  margin-bottom: 1.75rem !important;
}

.pb-6 {
  padding-bottom: 1.75rem !important;
}

.my-6 {
  margin-top: 1.75rem !important;
  margin-bottom: 1.75rem !important;
}

.py-6 {
  padding-top: 1.75rem !important;
  padding-bottom: 1.75rem !important;
}

.ml-6 {
  margin-left: 1.75rem !important;
}

.pl-6 {
  padding-left: 1.75rem !important;
}

.mr-6 {
  margin-right: 1.75rem !important;
}

.pr-6 {
  padding-right: 1.75rem !important;
}

.mx-6 {
  margin-left: 1.75rem !important;
  margin-right: 1.75rem !important;
}

.px-6 {
  padding-left: 1.75rem !important;
  padding-right: 1.75rem !important;
}

.pa-6 {
  padding: 1.75rem 1.75rem !important;
}

.ma-6 {
  margin: 1.75rem 1.75rem !important;
}

.mt-7 {
  margin-top: 2rem !important;
}

.pt-7 {
  padding-top: 2rem !important;
}

.mb-7 {
  margin-bottom: 2rem !important;
}

.pb-7 {
  padding-bottom: 2rem !important;
}

.my-7 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.py-7 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.ml-7 {
  margin-left: 2rem !important;
}

.pl-7 {
  padding-left: 2rem !important;
}

.mr-7 {
  margin-right: 2rem !important;
}

.pr-7 {
  padding-right: 2rem !important;
}

.mx-7 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.px-7 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.pa-7 {
  padding: 2rem 2rem !important;
}

.ma-7 {
  margin: 2rem 2rem !important;
}

.mt-8 {
  margin-top: 2.25rem !important;
}

.pt-8 {
  padding-top: 2.25rem !important;
}

.mb-8 {
  margin-bottom: 2.25rem !important;
}

.pb-8 {
  padding-bottom: 2.25rem !important;
}

.my-8 {
  margin-top: 2.25rem !important;
  margin-bottom: 2.25rem !important;
}

.py-8 {
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important;
}

.ml-8 {
  margin-left: 2.25rem !important;
}

.pl-8 {
  padding-left: 2.25rem !important;
}

.mr-8 {
  margin-right: 2.25rem !important;
}

.pr-8 {
  padding-right: 2.25rem !important;
}

.mx-8 {
  margin-left: 2.25rem !important;
  margin-right: 2.25rem !important;
}

.px-8 {
  padding-left: 2.25rem !important;
  padding-right: 2.25rem !important;
}

.pa-8 {
  padding: 2.25rem 2.25rem !important;
}

.ma-8 {
  margin: 2.25rem 2.25rem !important;
}

.mt-9 {
  margin-top: 2.5rem !important;
}

.pt-9 {
  padding-top: 2.5rem !important;
}

.mb-9 {
  margin-bottom: 2.5rem !important;
}

.pb-9 {
  padding-bottom: 2.5rem !important;
}

.my-9 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.py-9 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.ml-9 {
  margin-left: 2.5rem !important;
}

.pl-9 {
  padding-left: 2.5rem !important;
}

.mr-9 {
  margin-right: 2.5rem !important;
}

.pr-9 {
  padding-right: 2.5rem !important;
}

.mx-9 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}

.px-9 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.pa-9 {
  padding: 2.5rem 2.5rem !important;
}

.ma-9 {
  margin: 2.5rem 2.5rem !important;
}

.mt-10 {
  margin-top: 2.75rem !important;
}

.pt-10 {
  padding-top: 2.75rem !important;
}

.mb-10 {
  margin-bottom: 2.75rem !important;
}

.pb-10 {
  padding-bottom: 2.75rem !important;
}

.my-10 {
  margin-top: 2.75rem !important;
  margin-bottom: 2.75rem !important;
}

.py-10 {
  padding-top: 2.75rem !important;
  padding-bottom: 2.75rem !important;
}

.ml-10 {
  margin-left: 2.75rem !important;
}

.pl-10 {
  padding-left: 2.75rem !important;
}

.mr-10 {
  margin-right: 2.75rem !important;
}

.pr-10 {
  padding-right: 2.75rem !important;
}

.mx-10 {
  margin-left: 2.75rem !important;
  margin-right: 2.75rem !important;
}

.px-10 {
  padding-left: 2.75rem !important;
  padding-right: 2.75rem !important;
}

.pa-10 {
  padding: 2.75rem 2.75rem !important;
}

.ma-10 {
  margin: 2.75rem 2.75rem !important;
}

.mt-11 {
  margin-top: 3rem !important;
}

.pt-11 {
  padding-top: 3rem !important;
}

.mb-11 {
  margin-bottom: 3rem !important;
}

.pb-11 {
  padding-bottom: 3rem !important;
}

.my-11 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.py-11 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.ml-11 {
  margin-left: 3rem !important;
}

.pl-11 {
  padding-left: 3rem !important;
}

.mr-11 {
  margin-right: 3rem !important;
}

.pr-11 {
  padding-right: 3rem !important;
}

.mx-11 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.px-11 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.pa-11 {
  padding: 3rem 3rem !important;
}

.ma-11 {
  margin: 3rem 3rem !important;
}
