.statusLabel {
  color: var(--color-brand-secondary-light);
}

.title {
  color: var(--color-brand-primary-darkest);
}

.modifiedLabel {
  color: var(--color-product-light);
}

.ball {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--color-brand-primary-base);
}

.activeLabel {
  color: var(--color-brand-primary-dark);
}

.buttonHoverColor:hover {
  color: var(--color-neutral-light-5);
}
