.input {
  border-radius: var(--border-radius-sm);
  border-width: var(--border-width-small);
  border-color: var(--input-border-color);
  border-style: solid;
  padding: var(--spacing-inset-md);
  font-size: var(--font-size-base);
  background-color: var(--input-bg);
  color: var(--input-color);
  width: 100%;
}

.input:hover:not([disabled]) {
  box-shadow: 0px 3px 6px #00000029;
}

.input:focus:not([disabled]) {
  border-color: var(--color-neutral-dark-1);
}

.input[disabled] {
  color: var(--input-disabled-color);
  background-color: var(--input-readonly-bg);
  border-color: var(--color-neutral-light-3);
}

.input[readonly] {
  background-color: var(--input-readonly-bg);
  border-color: var(--color-neutral-light-3);
}

.iconInsideLeft {
  padding-left: 50px;
}

.iconInsideRight {
  padding-right: 50px;
}

input[type='file'] {
  display: none;
}

.inputfile span {
  color: var(--input-color);
}
