.section,
.illustration {
  background: var(--color-neutral-light-5);
  margin-top: 83px;
  height: calc(100vh - 83px);
}

.section {
  padding: var(--spacing-stack-sm) var(--spacing-inline-md)
    var(--spacing-stack-sm) var(--spacing-inline-lg) !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.illustration {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.links {
  margin-top: var(--spacing-stack-3xs);
  gap: 48px;
}

.logo {
  max-width: 175px;
}

.content {
  text-align: center;
}

.welcomeText {
  color: var(--color-brand-primary-darkest);
}

.description {
  color: var(--color-brand-secondary-darkest);
}

.actions > span {
  color: var(--color-neutral-dark-2);
  margin: var(--spacing-stack-4xs) 0;
}

.actionsWithText {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-inset-4xl);
  margin: var(--spacing-stack-4xs) 0;
}

.accountLink {
  text-decoration: none;
  color: var(--color-brand-primary-dark);
  cursor: pointer;
  margin-left: var(--spacing-inline-5xs);
  font-weight: var(--font-weight-bold);
}

.actions button span {
  margin: auto;
}

.footer {
  color: var(--color-neutral-dark-2);
}

@media (min-height: 1064px) {
  .content {
    margin-bottom: 35%;
  }
}

@media (max-width: 991px) {
  .section,
  .illustration {
    height: auto;
  }

  .section {
    padding: var(--spacing-squish-lg) !important;
    padding-top: var(--spacing-stack-lg) !important;
  }

  .illustration {
    margin-top: 0;
    flex-direction: column-reverse;
    gap: 67px;
  }

  .actionsWithText {
    margin-top: var(--spacing-stack-sm);
  }

  .container {
    background: var(--color-neutral-light-5);
  }

  .links {
    margin-top: 0;
    gap: var(--spacing-stack-sm);
  }

  .imageLink {
    height: 48px;
    width: 161px;
  }
}

@media (max-width: 375px) {
  .imageLink {
    width: 145px;
  }
}
