@import 'src/libs/conecte-se/styles/core';
@import 'src/libs/inmaster-ui/build/assets/style';
@import 'react-flexbox-grid-fix';
@import 'titles';
@import 'texts';
@import 'helper-classes';

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&family=Roboto:wght@300;400;700;900&display=swap');

* {
  margin: 0;
  padding: 0;
  font-weight: 400;
  box-sizing: border-box;
  font-family: var(--font-family-secondary), var(--font-family-primary-fallback),
    sans-serif;
}

body {
  background-color: var(--page-bg);
  color: var(--page-font-color);
}

* {
  scrollbar-color: #888 #f1f1f1 !important;
  scrollbar-width: 5px !important;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.rc-tooltip-placement-top {
  z-index: 1000;
}
