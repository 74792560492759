/* context variables */
body {
  /* page */
  --page-bg: var(--color-neutral-light-4);
  --page-font-color: var(--color-neutral-dark-1);
  --page-header-bg: var(--color-neutral-light-5);
  --page-footer-bg: var(--color-neutral-light-4);
  --page-footer-border: var(--color-neutral-light-3);
  --page-login-bg: var(--color-neutral-light-5);

  /* input */
  --input-color: var(--color-neutral-dark-1);
  --input-placeholder-color: var(--color-neutral-dark-5);
  --input-bg: var(--color-neutral-light-5);
  --input-border-color: var(--color-neutral-dark-5);
  --input-readonly-bg: var(--color-neutral-light-3);
  --input-disabled-color: var(--color-neutral-light-1);
  --input-label-color: var(--color-neutral-dark-1);
  --input-icon-color: var(--color-neutral-light-2);
  --input-icon-action-color: var(--color-neutral-dark-2);

  /* button */
  --bg-button-secondary: var(--color-neutral-dark-2);
  --bg-button-tertiary: var(--color-product-dark);
  --button-disabled: var(--color-neutral-light-1);
  --bg-button-disabled: var(--color-neutral-light-3);
  --button-outline-color: var(--color-neutral-dark-1);
  --button-outline-focus-bg: var(--color-brand-secondary-base);
  --button-outline-focus-border: var(--color-neutral-light-5);
  --button-outline-focus-color: var(--color-neutral-light-5);
  --button-ghost-color: var(--color-neutral-dark-1);

  /* radio */
  --radio-background-color: var(--color-neutral-light-5);
  --radio-border-color: var(--color-neutral-dark-1);
  --radio-dot-color: var(--color-neutral-dark-1);
  --radio-disabled-color: var(--color-neutral-light-3);

  /* checkbox */
  --checkbox-bg: var(--color-neutral-light-4);
  --checkbox-border-color: var(--color-neutral-dark-1);
  --checkbox-disabled-color: var(--color-neutral-light-3);

  /* toggle */
  --toggle-off-bg-color: var(--color-neutral-light-3);
  --toggle-on-bg-color: var(--color-product-dark);
  --toggle-on-dot-color: var(--color-neutral-light-5);
  --toggle-disabled-bg-color: var(--color-neutral-light-3);
  --toggle-disabled-dot-color: var(--color-neutral-light-2);
  --toggle-color-bg-color: var(--color-status-ok-base);

  /* modal */
  --modal-bg: var(--color-neutral-light-5);
  --modal-border: var(--color-neutral-light-3);

  /* section */
  --section-border: var(--color-neutral-light-3);

  /* table */
  --table-header-bg: var(--color-product-dark);
  --table-border: var(--color-neutral-light-3);
  --table-icon: var(--color-neutral-dark-2);

  /* dropdown */
  --dropdown-bg: var(--color-neutral-light-5);
  --dropdown-border: var(--color-neutral-light-3);

  /* scrollbar */
  --scrollbar-thumb-bg: var(--color-neutral-light-10);
  --scrollbar-track-bg: var(--color-gray-opacity-3);

  /* Form */
  --title-color: var(--color-neutral-light-8);
  --subtitle-color: var(--color-neutral-light-9);

  /* Wan advanced */
  --wan-advanced-color: var(--color-neutral-dark-7);

  /* Dashboard */
  --dashboard-color: var(--color-neutral-dark-6);
  --dashboard-bg: var(--color-neutral-light-5);
  --dashboard-rect-top-color: var(--color-neutral-dark-8);

  /* Card */
  --card-color: var(--color-neutral-dark-2);
  --card-bg: var(--color-neutral-light-5);
  --card-outline: var(--color-brand-secondary-light);

  /* Popup menu */
  --popup-menu-bg: var(--color-neutral-light-4);
  --popup-menu-action-color: var(--color-brand-primary-dark);
  --popup-menu-shadow-color: var(--color-neutral-dark-1);

  /* Breadcrumb */
  --breadcrumb-option-text-color: var(--color-neutral-dark-1);
  --breadcrumb-option-current-color: var(--color-brand-secondary-lightest);
  --breadcrumb-container-bg: var(--color-neutral-light-3);

  /* ResponseMessage */
  --response-message-color: var(--color-neutral-dark-2);

  /* Loading */
  --loading-bg: var(--color-status-ok-light);
  --loading-inside-bg: var(--color-status-ok-base);

  /* Steps */
  --circle-step-color: var(--color-neutral-light-1);
  --active-circle-step-color: var(--color-brand-secondary-light);
  --before-active-circle-step-color: var(--color-neutral-light-5);
  --before-active-circle-step-bg: var(--color-brand-secondary-light);
  --before-circle-step-bg: var(--color-neutral-light-3);
  --before-circle-step-border-color: var(--color-neutral-light-5);
  --after-active-circle-step-bg: var(--color-brand-secondary-light);
  --after-circle-step-bg: var(--color-neutral-light-3);
}

/* dark mode customs */
body[data-theme='dark'] {
  /* page */
  --page-bg: var(--color-product-dark);
  --page-font-color: var(--color-neutral-light-5);
  --page-header-bg: var(--color-neutral-dark-10);
  --page-footer-bg: var(--color-product-dark);
  --page-footer-border: var(--color-neutral-dark-3);
  --page-login-bg: var(--color-product-dark);

  /* input */
  --input-color: var(--color-neutral-light-5);
  --input-placeholder-color: var(--color-neutral-light-1);
  --input-bg: var(--color-product-base);
  --input-border-color: var(--color-neutral-light-5);
  --input-readonly-bg: var(--color-brand-secondary-darkest);
  --input-disabled-color: var(--color-neutral-light-1);
  --input-label-color: var(--color-neutral-light-5);
  --input-icon-color: var(--color-neutral-light-5);
  --input-icon-action-color: var(--color-neutral-light-5);

  /* button */
  --bg-button-secondary: var(--color-neutral-light-5);
  --bg-button-tertiary: var(--color-product-light);
  --button-disabled: var(--color-brand-secondary-base);
  --bg-button-disabled: var(--color-brand-secondary-dark);
  --button-outline-color: var(--color-neutral-light-5);
  --button-outline-focus-border: var(--color-neutral-light-5);
  --button-outline-focus-bg: var(--color-brand-secondary-dark);
  --button-ghost-color: var(--color-neutral-light-5);

  /* radio */
  --radio-background-color: var(--color-product-dark);
  --radio-border-color: var(--color-neutral-light-5);
  --radio-dot-color: var(--color-neutral-light-5);
  --radio-disabled-color: var(--color-product-base);

  /* checkbox */
  --checkbox-border-color: var(--color-neutral-light-5);
  --checkbox-disabled-color: var(--color-product-base);

  /* toggle */
  --toggle-off-bg-color: var(--color-brand-secondary-base);
  --toggle-on-bg-color: var(--color-neutral-light-5);
  --toggle-on-dot-color: var(--color-product-dark);
  --toggle-disabled-bg-color: var(--color-brand-secondary-dark);
  --toggle-disabled-dot-color: var(--color-product-dark);
  --toggle-color-bg-color: var(--color-status-ok-light);

  /* modal */
  --modal-bg: var(--color-product-base);
  --modal-border: var(--color-neutral-dark-4);

  /* section */
  --section-border: var(--color-neutral-dark-3);

  /* table */
  --table-header-bg: var(--color-product-light);
  --table-border: var(--color-neutral-dark-4);
  --table-icon: var(--color-neutral-light-5);

  /* dropdown */
  --dropdown-bg: #272f33;
  --dropdown-border: var(--color-neutral-dark-3);

  /* scrollbar */
  --scrollbar-thumb-bg: var(--color-brand-primary-base);
  --scrollbar-track-bg: var(--color-gray-opacity-3);

  /* Form */
  --title-color: var(--color-neutral-light-5);
  --subtitle-color: var(--color-neutral-light-5);

  /* Wan advanced */
  --wan-advanced-color: var(--color-product-light);

  /* Dashboard */
  --dashboard-color: var(--color-neutral-light-5);
  --dashboard-bg: var(--color-product-base);
  --dashboard-rect-top-color: var(--color-neutral-light-4);

  /* Card */
  --card-color: var(--color-neutral-light-5);
  --card-bg: var(--color-brand-secondary-base);

  /* Popup menu */
  --popup-menu-bg: var(--color-neutral-dark-10);
  --popup-menu-action-color: var(--color-brand-primary-light);
  --popup-menu-shadow-color: var(--color-neutral-dark-1);

  /* Breadcrumb */
  --breadcrumb-option-text-color: var(--color-brand-secondary-lightest);
  --breadcrumb-option-current-color: var(--color-neutral-light-5);
  --breadcrumb-container-bg: var(--color-brand-secondary-dark);

  /* ResponseMessage */
  --response-message-color: var(--color-neutral-light-5);

  /* Loading */
  --loading-bg: var(--color-brand-secondary-darkest);
  --loading-inside-bg: var(--color-status-ok-base);

  /* Steps */
  --circle-step-color: var(--color-neutral-light-3);
  --active-circle-step-color: var(--color-neutral-light-5);
  --before-active-circle-step-color: var(--color-neutral-light-5);
  --before-active-circle-step-bg: var(--color-brand-secondary-light);
  --before-circle-step-bg: var(--color-neutral-light-3);
  --before-circle-step-border-color: var(--color-neutral-light-5);
  --after-active-circle-step-bg: var(--color-brand-secondary-light);
  --after-circle-step-bg: var(--color-neutral-light-3);
}
