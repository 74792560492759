.helperDrawer {
  position: relative;
}

.ninaHelper {
  display: flex;
  align-items: center;
  padding: var(--spacing-squish-xxs) var(--spacing-inset-4xl);
  width: 100%;
  background: var(--color-neutral-light-4);
  border: 1px solid var(--color-neutral-light-2);
  border-radius: var(--border-radius-sm);
}

.ninaHelperInfos {
  display: flex;
  flex-direction: column;
  margin-left: var(--spacing-squish-md);
}

.ninaButtonText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: var(--spacing-inset-xs);
}

.helpResults {
  height: calc(100vh - 340px);
  overflow: auto;
}

.noSearchedItems {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  height: calc(100% - 45px);
}
