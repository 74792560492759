.container {
  display: flex;
  position: relative;
  cursor: pointer;
  width: fit-content;
}

.container input {
  display: none;
}

.checkmark {
  height: 20px;
  width: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: var(--checkbox-border-color);
  border-radius: calc(var(--border-radius-sm) - 1px);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s;
}

.container input:checked ~ .checkmark {
  background-color: var(--color-neutral-dark-1);
  color: var(--color-neutral-light-5);
}

.disabled input:checked ~ .checkmark {
  background-color: var(--checkbox-disabled-color);
}

.disabled span {
  border-color: var(--checkbox-disabled-color);
  background-color: var(--checkbox-disabled-color);
}

.disabled {
  pointer-events: none;
  color: var(--checkbox-disabled-color);
}

.checkmark svg > path:last-child {
  stroke-width: 2;
}
