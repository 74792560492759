.percentageContainer {
  width: 100%;
  display: flex;
  justify-content: cernte;
  align-items: center;
  gap: 12px;
}

/* Remove the min and max label */
.percentageContainer > div > div:nth-child(1) {
  display: none;
}
.percentageContainer > div > div:nth-child(3) {
  display: none;
}
