.title {
  color: var(--color-neutral-dark-3);
}

.shellSites {
  padding: var(--spacing-stack-2xl) !important;
  padding-bottom: var(--spacing-stack-xl) !important;
}

.cardOfSitesAndSharedSites {
  min-height: calc(100vh - 300px);
}

.cardSite {
  width: 100%;
  padding: 0;
  color: var(--color-neutral-dark-3);
}

.menuActions {
  margin-top: -6px;
  cursor: pointer;
  z-index: 1;
}

.textDisabled {
  color: #e2e2e2;
}

.dueToneIcons {
  margin-right: var(--spacing-stack-2xl);
}

.skeletonSites {
  margin-bottom: var(--spacing-inset-xl);
}

.rightCardBody {
  height: 112px;
}
