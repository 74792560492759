.sidebar {
  min-width: 345px;
  width: 345px;
  height: 100vh;
  position: relative;
  transition: width 0.4s, min-width 0.4s;
  background: transparent
    linear-gradient(180deg, #181e22 0%, #2c383d 15%, #181e22 100%) 0% 0%
    no-repeat padding-box;
  z-index: 9;
}

.sidebarFixed {
  position: fixed;
}

.sidebarMinimized {
  min-width: 75px;
  width: 75px;
}

.sidebarHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 265px;
  transition: 0.4s;
  pointer-events: none;
}

.sidebarHeaderBackground {
  background: transparent
    linear-gradient(131deg, #181e22 0%, #2c383d 0%, #181e22 100%) 0% 0%
    no-repeat padding-box;
  width: 100%;
  height: 265px;
  display: flex;
  flex-direction: column;
  transition: 0.4s;
}

.sidebarHeaderMinimized {
  opacity: 0;
}

.shellOpenOrCloseButton {
  width: 100%;
  position: relative;
  height: 0px;
}

.openOrCloseSidebarButton {
  font-size: 28px;
  color: var(--color-neutral-light-5);
  position: absolute;
  top: 30px;
  left: 25px;
  cursor: pointer;
}

.logo {
  width: 60%;
}

.sidebarActions {
  width: 100%;
  height: 100%;
  background: transparent
    linear-gradient(180deg, #181e22 0%, #2c383d 15%, #181e22 100%) 0% 0%
    no-repeat padding-box;
}

.sidebarMinimized .sidebarActions,
.sidebarMinimized .sidebarHeaderBackground {
  background: none;
}

.sidebarMinimized .sidebarHeaderBackground {
  height: 30vh;
}

.skeletonSideBarAction {
  width: 100%;
  height: 55px;
  background: transparent;
  border: none;
  border-bottom: 1px solid var(--color-neutral-dark-1);
  padding: var(--spacing-inset-xl) var(--spacing-inset-4xl);
  color: var(--color-neutral-light-5);
}
