.container {
  position: fixed;
  z-index: 1000;
  max-width: 100vw;

  background-color: var(--color-status-ok-lightest);
  border-top: 1px solid var(--brand-color-primary-lightest);
  padding: 0 0 var(--spacing-stack-xs) var(--spacing-squish-lg);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
}

.links {
  margin-top: var(--spacing-stack-3xs);
  gap: var(--spacing-stack-sm);
}

.closeButton {
  padding-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.imageLink {
  height: 48px;
  width: 157px;
}
