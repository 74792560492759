.horizontalDivider {
  width: 100%;
  height: 1px;
  background: var(--color-neutral-light-1);
  margin: var(--spacing-stack-xs) 0;
}

.verticalDivider {
  border-left: 1px solid var(--color-neutral-light-3);
  height: 40px;
  margin: 0 var(--spacing-inline-3xs);
}
