.containerIcon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-sm);
  margin-right: var(--spacing-inset-2xs);
}

.value {
  font-size: var(--font-size-sm);
}
