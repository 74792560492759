.progressBarContainer {
  display: flex;
  justify-content: flex-start;
}

.progressBarMain {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.progressBarShell {
  width: var(--progress-bar-width);
  height: var(--spacing-stack-6xs);
  overflow: hidden;
}

.warning {
  background-color: var(--color-status-alert-lightest);
}

.warning > div {
  background-color: var(--color-status-alert-base);
}

.error {
  background-color: var(--color-status-critical-base);
}

.error > div {
  display: none;
}

.success {
  background-color: var(--color-brand-primary-lightest);
}

.success > div {
  background-color: var(--color-brand-primary-base);
}

.progressBarInside {
  height: var(--spacing-stack-6xs);
}

.responseMessage {
  margin-top: var(--spacing-stack-5xs);
  display: flex;
  align-items: center;
}
