.iframeShell {
  min-height: 60vh !important;
}

.modalBody {
  padding-top: var(--spacing-stack-xs);
  border-top: 1px solid var(--color-neutral-light-3);
  border-bottom: 1px solid var(--color-neutral-light-3);
}

.hidden {
  display: none !important;
}
